import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import PrivateRoute from "./PrivateRoute.js";
import RoutePaths from "./index.js";
import ErrorPage from "../404/ErrorPage";

const ScrollTop = ({ children }) => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname, hash]);

  return children;
};

function Router() {
  return (
    <ScrollTop>
      <Routes>
        {RoutePaths.map((route, index) => {
          const Component = route.component;
          return (
            <Route
              key={index}
              path={route.path}
              element={
                route.meta.authRoute ? (
                  <PrivateRoute>
                    <Component />
                  </PrivateRoute>
                ) : (
                  <Component />
                )
              }
            />
          );
        })}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </ScrollTop>
  );
}

export default Router;
