import { createSlice } from "@reduxjs/toolkit";
import {
  getAllUsers,
  getUser,
  softDeleteUser,
  softDeleteUsers,
  undoDeleteUser,
  updateUser,
} from "./UserApi";

// Slice for managing master category state
const userSlice = createSlice({
  name: "user",
  initialState: {
    allUsers: [],
    users: [],
    loading: false,
    error: null,
    dataLimit: 0,
    currentPage: 0,
    totalPages: 0,
    totalUsers: 0,
  },
  extraReducers: (builder) => {
    // GET VENDOR FILTER WISE DATA
    builder.addCase(getUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.loading = false;
      state.users = action.payload.data;
      state.dataLimit = action.payload.meta.limit;
      state.currentPage = action.payload.meta.page;
      state.totalPages = action.payload.meta.totalPages;
      state.totalUsers = action.payload.meta.total;
      state.dataFetched = true;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // GET All VENDOR DATA
    builder.addCase(getAllUsers.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.allUsers = action.payload.data;
    });
    builder.addCase(getAllUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // UPDATE VENDOR DATA
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      const index = state.users.findIndex(
        (user) => user._id === action.payload._id
      );
      if (index !== -1) {
        state.users[index] = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // SOFT DELETE VENDOR DATA
    builder.addCase(softDeleteUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(softDeleteUser.fulfilled, (state, action) => {
      const deletedId = action.payload.data._id || [];
      state.users = state.users.filter((user) => !deletedId.includes(user._id));
      state.loading = false;
    });
    builder.addCase(softDeleteUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // UNDO DELETE VENDOR DATA
    builder.addCase(undoDeleteUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(undoDeleteUser.fulfilled, (state, action) => {
      const undodeletedId = action.payload.data._id || [];
      state.users = state.users.filter(
        (user) => !undodeletedId.includes(user._id)
      );
      state.loading = false;
    });
    builder.addCase(undoDeleteUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // MULTIPLE MASTER_CATEGORY DATA
    builder.addCase(softDeleteUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(softDeleteUsers.fulfilled, (state, action) => {
      const deletedIds = action.payload || [];
      state.users = state.users.filter(
        (user) => !deletedIds.includes(user._id)
      );
      state.loading = false;
    });
    builder.addCase(softDeleteUsers.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export default userSlice.reducer;
