import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";
import axiosInstanceJson from "../../../api/axiosInstanceJson";

export const getKeyWord = createAsyncThunk(
  "get-keyWord/get",
  async (_, thunkAPI) => {
    try {
      const { data } = await axiosInstance.get(`/v1/admin/keyWord/get-keyWord`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Unique action types for each thunk
export const getMasterCategory = createAsyncThunk(
  "masterCategory/get",
  async (_, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.get(
        `/v1/vendor/general/get-masterCategory`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getMainCategory = createAsyncThunk(
  "mainCategory/get",
  async ({ masterCategoryId }, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.get(
        `/v1/vendor/general/get-mainCategory/${masterCategoryId}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getSubCategory = createAsyncThunk(
  "subCategory/get",
  async ({ mainCategoryId }, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.get(
        `/v1/vendor/general/get-subCategory/${mainCategoryId}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// GET ALL VENDOR
export const getAllVendor = createAsyncThunk(
  "getAllVendor",
  async (_, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.get(
        `/v1/admin/vendor/get-allVendor`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// GET ALL USERS
export const getAllUser = createAsyncThunk(
  "getAllUser", // Changed from "getAllVendor" to "getAllUser"
  async (_, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.get(`/v1/user/get-AllUser`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getSizeLabel = createAsyncThunk(
  "get-sizeLabel/get",
  async (_, thunkAPI) => {
    try {
      const { data } = await axiosInstance.get(
        `/v1/vendor/general/get-sizeLabel`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
