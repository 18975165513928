import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";

export const getInquiry = createAsyncThunk(
  "inquiry/getAll",
  async (
    {
      id,
      status = "pending",
      search = "",
      page = 1,
      limit = 10,
      startDate,
      endDate,
      paginate,
    },
    thunkAPI
  ) => {
    try {
      const { data } = await axiosInstance.get(
        `/v1/admin/inquiry/get-inquiry`,
        {
          params: {
            id,
            status,
            search,
            page,
            limit,
            startDate,
            paginate,
            endDate,
          },
        }
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateInquiry = createAsyncThunk(
  "inquiry/updateInquiry",
  async ({ inquiryId, subAdminId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/v1/admin/inquiry/accept-inquiry/${inquiryId}`,
        {
          subAdminId,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const closeInquiry = createAsyncThunk(
  "inquiry/updateInquiry",
  async ({ inquiryId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/v1/admin/inquiry/close-inquiry/${inquiryId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
