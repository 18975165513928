import { createSlice } from "@reduxjs/toolkit";
import {
  getAllVendor,
  getVendor,
  // getVendorById,
  postVendor,
  softDeleteVendor,
  softDeleteVendors,
  undoDeleteVendor,
  updateVendor,
} from "./VendorApi";

// Slice for managing master category state
const vendorSlice = createSlice({
  name: "vendor",
  initialState: {
    allVendor: [],
    vendors: [],
    loading: false,
    error: null,
    dataLimit: 0,
    currentPage: 0,
    totalPages: 0,
    totalCategories: 0,
  },
  extraReducers: (builder) => {
    // POST VENDOR REDUCERS
    builder.addCase(postVendor.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postVendor.fulfilled, (state, action) => {
      state.vendors.push(action.payload.vendor);
      state.loading = false;
    });
    builder.addCase(postVendor.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // GET VENDOR FILTER WISE DATA
    builder.addCase(getVendor.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getVendor.fulfilled, (state, action) => {
      state.loading = false;
      state.vendors = action.payload.data;
      state.dataLimit = action.payload.meta.limit;
      state.currentPage = action.payload.meta.page;
      state.totalPages = action.payload.meta.totalPages;
      state.totalCategories = action.payload.meta.total;
      state.dataFetched = true;
    });
    builder.addCase(getVendor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // GET All VENDOR DATA
    builder.addCase(getAllVendor.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllVendor.fulfilled, (state, action) => {
      state.loading = false;
      state.allVendor = action.payload.data;
    });
    builder.addCase(getAllVendor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // UPDATE VENDOR DATA
    builder.addCase(updateVendor.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateVendor.fulfilled, (state, action) => {
      const index = state.vendors.findIndex(
        (vendor) => vendor._id === action.payload._id
      );
      if (index !== -1) {
        state.vendors[index] = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(updateVendor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // SOFT DELETE VENDOR DATA
    builder.addCase(softDeleteVendor.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(softDeleteVendor.fulfilled, (state, action) => {
      const deletedId = action.payload.data._id || [];
      state.vendors = state.vendors.filter(
        (vendor) => !deletedId.includes(vendor._id)
      );
      state.loading = false;
    });
    builder.addCase(softDeleteVendor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // UNDO DELETE VENDOR DATA
    builder.addCase(undoDeleteVendor.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(undoDeleteVendor.fulfilled, (state, action) => {
      const undodeletedId = action.payload.data._id || [];
      state.vendors = state.vendors.filter(
        (vendor) => !undodeletedId.includes(vendor._id)
      );
      state.loading = false;
    });
    builder.addCase(undoDeleteVendor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // MULTIPLE MASTER_CATEGORY DATA
    builder.addCase(softDeleteVendors.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(softDeleteVendors.fulfilled, (state, action) => {
      const deletedIds = action.payload || [];
      state.vendors = state.vendors.filter(
        (vendor) => !deletedIds.includes(vendor._id)
      );
      state.loading = false;
    });
    builder.addCase(softDeleteVendors.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export default vendorSlice.reducer;
