import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { menuItems } from "../utils/SidebarMenu";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import logo from "../assets/logo.png";
import { HiBell } from "react-icons/hi";
import { BiSupport } from "react-icons/bi";

function SideBar() {
  const [openDropdown, setOpenDropdown] = useState(null);
  const location = useLocation();
  const [time, setTime] = useState(new Date());
  const navigate = useNavigate();

  const vendorDetails = JSON.parse(localStorage.getItem("user"));

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (date) => {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const formatDate = (date) => {
    return date.toLocaleDateString([], {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const handleChatPage = () => {
    navigate("/chat-page");
  };

  return (
    <div className="w-56 bg-lightColor h-full flex flex-col justify-between shadow-md text-primary p-4">
      <div>
        <div className="flex justify-center p-5">
          <img src={logo} alt="logo" />
        </div>
        <div className="flex items-center mt-3 border-t border-b border-primary">
          <button className="flex justify-center items-center  gap-1 w-28 py-2 px-1 border-r border-primary hover:bg-primary hover:text-white">
            <span className="text-xl">
              <HiBell />
            </span>
            <span>Notice</span>
          </button>
          <button
            onClick={handleChatPage}
            className="flex justify-center items-center gap-1 w-28 py-2 px-1 hover:bg-primary hover:text-white"
          >
            <span className="text-xl">
              <BiSupport />
            </span>
            <span>Chat</span>
          </button>
        </div>
        <div className="text-sm text-primary mt-5 border-b border-primary pb-1 mb-3">
          Manage Business
        </div>
        {menuItems.map((item, index) => (
          <div key={index} className="block">
            <div
              onClick={() => item.subMenu && toggleDropdown(index)}
              className={`flex items-center justify-between gap-2 mt-2 px-2 py-2 w-full hover:bg-primary hover:text-white cursor-pointer ${
                item.subMenu && openDropdown === index
                  ? "bg-primary text-white"
                  : ""
              } ${
                item.path === location.pathname ? "bg-primary text-white" : ""
              }`}
            >
              <Link to={item.path} className="flex items-center gap-2 w-full">
                {item.icon}
                <span className="text-base">{item.label}</span>
              </Link>
              {item.subMenu && (
                <div>
                  {openDropdown === index ? <FaChevronUp /> : <FaChevronDown />}
                </div>
              )}
            </div>
            <div
              className={`${
                openDropdown === index
                  ? "max-h-40 opacity-100"
                  : "max-h-0 opacity-0"
              } overflow-hidden transition-all duration-500 ease-in-out`}
            >
              {item.subMenu && (
                <div className="ml-4 mt-1">
                  {item.subMenu.map((subItem, subIndex) => (
                    <Link key={subIndex} to={subItem.path} className="block">
                      <div
                        className={`flex items-center gap-2 px-2 py-2 w-full hover:bg-primary hover:text-white cursor-pointer ${
                          subItem.path === location.pathname
                            ? "bg-primary text-white"
                            : ""
                        }`}
                      >
                        {item.icon}
                        <span className="text-base">{subItem.label}</span>
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <div>
        <div className="flex justify-between items-center">
          <div className="text-gray-800 font-semibold">
            <div>{formatTime(time)}</div>
            <div className="text-sm">{formatDate(time)}</div>
          </div>
        </div>
        {vendorDetails && (
          <div className="border-t border-primary ">
            <div className="mt-3 rounded-lg bg-primary text-white p-3 flex items-center gap-2">
              <div className="bg-white p-2 rounded-full text-primary font-semibold">
                {vendorDetails.authName
                  .split(" ")
                  .map((n) => n[0].toUpperCase())
                  .join("")}
              </div>
              <div className="text-sm">
                <div>{vendorDetails.authName}</div>
                <div>{vendorDetails.role}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SideBar;
