import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstanceJson from "../../../api/axiosInstanceJson";

// POST VENDOR DATA
export const postVendor = createAsyncThunk(
  "vendor/post",
  async (vendordata, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.post(
        `/v1/admin/vendor/post-vendor`,
        vendordata
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// GET VENDOR DATA FILTER WISE
export const getVendor = createAsyncThunk(
  "vendor/getAll",
  async (
    {
      status = "All",
      search = "",
      page = 1,
      limit = 10,
      startDate,
      endDate,
      paginate,
    },
    thunkAPI
  ) => {
    try {
      const { data } = await axiosInstanceJson.get(
        `/v1/admin/vendor/get-vendor`,
        {
          params: {
            status,
            search,
            page,
            limit,
            startDate,
            endDate,
            paginate,
          },
        }
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// GET ALL VENDOR
export const getAllVendor = createAsyncThunk(
  "getAllVendor",
  async (_, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.get(
        `/v1/admin/vendor/get-allVendor`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// GET VENDOR BY ID
export const getVendorById = createAsyncThunk(
  "vendor/getById",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.get(
        `/v1/admin/vendor/get-singleVendor/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// UPDATE VENDOR DATA
export const updateVendor = createAsyncThunk(
  "vendor/update",
  async (authData, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.put(
        `/v1/admin/vendor/update-vendor/${authData._id}`,
        authData
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// SOFT DELETE VENDOR DATA
export const softDeleteVendor = createAsyncThunk(
  "vendor/delete",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.put(
        `/v1/admin/vendor/soft-delete-vendor/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// UNDO DELETE VENDOR DATA
export const undoDeleteVendor = createAsyncThunk(
  "vendor/undoDelete",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.put(
        `/v1/admin/vendor/undo-delete-vendor/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// MULTIPLE DELETE VENDORS DATA
export const softDeleteVendors = createAsyncThunk(
  "vendors/softDeleteVendors",
  async (ids, thunkAPI) => {
    try {
      await axiosInstanceJson.put(`/v1/admin/vendor/delete-selected-data`, {
        ids,
      });
      return ids;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
