import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstancejson from "../../../api/axiosInstanceJson";

export const starMessage = createAsyncThunk(
  "chat/starMessage",
  async ({ messageId }, thunkAPI) => {
    try {
      const response = await axiosInstancejson.post(`/v1/admin/chat/star`, {
        _id: messageId,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const unStarMessage = createAsyncThunk(
  "chat/unStarMessage",
  async ({ messageId }, thunkAPI) => {
    try {
      const response = await axiosInstancejson.post(`/v1/admin/chat/unstar`, {
        _id: messageId,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
