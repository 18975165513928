import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstanceJson from "../../../api/axiosInstanceJson";

// POST SUBADMIN DATA
export const postSubAdmin = createAsyncThunk(
  "subAdmin/post",
  async (subAdmindata, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.post(
        `/v1/admin/subAdmin/post-subAdmin`,
        subAdmindata
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// GET SUBADMIN DATA FILTER WISE
export const getSubAdmin = createAsyncThunk(
  "subAdmin/getAll",
  async (
    {
      status = "All",
      search = "",
      page = 1,
      limit = 10,
      startDate,
      endDate,
      paginate,
    },
    thunkAPI
  ) => {
    try {
      const { data } = await axiosInstanceJson.get(
        `/v1/admin/subAdmin/get-subAdmin`,
        {
          params: {
            status,
            search,
            page,
            limit,
            startDate,
            endDate,
            paginate,
          },
        }
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// GET ALL SUBADMIN
export const getAllSubAdmin = createAsyncThunk(
  "getAllSubAdmin",
  async (_, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.get(
        `/v1/admin/subAdmin/get-allSubAdmin`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// GET SUBADMIN BY ID
export const getSubAdminById = createAsyncThunk(
  "subAdmin/getById",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.get(
        `/v1/admin/subAdmin/get-singleSubAdmin/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// UPDATE SUBADMIN DATA
export const updateSubAdmin = createAsyncThunk(
  "subAdmin/update",
  async (authData, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.put(
        `/v1/admin/subAdmin/update-subAdmin/${authData._id}`,
        authData
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// SOFT DELETE SUBADMIN DATA
export const softDeleteSubAdmin = createAsyncThunk(
  "subAdmin/delete",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.put(
        `/v1/admin/subAdmin/soft-delete-subAdmin/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// UNDO DELETE SUBADMIN DATA
export const undoDeleteSubAdmin = createAsyncThunk(
  "subAdmin/undoDelete",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.put(
        `/v1/admin/subAdmin/undo-delete-subAdmin/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// MULTIPLE DELETE SUBADMINS DATA
export const softDeleteSubAdmins = createAsyncThunk(
  "subAdmins/softDeleteVendors",
  async (ids, thunkAPI) => {
    try {
      await axiosInstanceJson.put(`/v1/admin/subAdmin/delete-selected-data`, {
        ids,
      });
      return ids;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
