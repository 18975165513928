import { createSlice } from "@reduxjs/toolkit";
import { deleteMessage, fetchChatMessages, starMessage } from "./chatApi";

const initialState = {
  messages: [],
  loading: false,
  error: null,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(starMessage.fulfilled, (state, action) => {
      const updatedMessage = action.payload;
      state.messages = state.messages.map((msg) =>
        msg.id === updatedMessage.id ? updatedMessage : msg
      );
    });
  },
});

export default chatSlice.reducer;
