import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/Auth/authSlice";
import masterCategoryReducer from "../features/MasterCategory/masterCategorySlice";
import mainCategoryReducer from "../features/MainCategory/mainCategorySlice";
import subCategoryReducer from "../features/SubCategory/subCategorySlice";
import productReducer from "../features/Products/productSlice";
import vendorReducer from "../features/Vendor/VendorSlice";
import subAdminReducer from "../features/SubAdmin/SubAdminSlice";
import generalReducer from "../features/Genral/generalSlice";
import userReducer from "../features/User/UserSlice";
import chatReducer from "../features/Chat/chatSlice";
import inquiryReducer from "../features/Inquiry/InquirySlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    masterCategory: masterCategoryReducer,
    mainCategory: mainCategoryReducer,
    subCategory: subCategoryReducer,
    product: productReducer,
    generalCategory: generalReducer,
    vendor: vendorReducer,
    subAdmin: subAdminReducer,
    user: userReducer,
    chat: chatReducer,
    inquiry: inquiryReducer,
  },
});
