import { createSlice } from "@reduxjs/toolkit";
import {
  getAllSubAdmin,
  getSubAdmin,
  postSubAdmin,
  softDeleteSubAdmin,
  softDeleteSubAdmins,
  undoDeleteSubAdmin,
  updateSubAdmin,
} from "./SubAdminApi";

// Slice for managing SUBADMIN state
const subAdminSlice = createSlice({
  name: "vendor",
  initialState: {
    allSubAdmin: [],
    subAdmins: [],
    loading: false,
    error: null,
    dataLimit: 0,
    currentPage: 0,
    totalPages: 0,
    totalCategories: 0,
  },
  extraReducers: (builder) => {
    // POST SUBADMIN REDUCERS
    builder.addCase(postSubAdmin.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postSubAdmin.fulfilled, (state, action) => {
      state.subAdmins.push(action.payload.subAdmin);
      state.loading = false;
    });
    builder.addCase(postSubAdmin.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // GET SUBADMIN FILTER WISE DATA
    builder.addCase(getSubAdmin.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSubAdmin.fulfilled, (state, action) => {
      state.loading = false;
      state.subAdmins = action.payload.data;
      state.dataLimit = action.payload.meta.limit;
      state.currentPage = action.payload.meta.page;
      state.totalPages = action.payload.meta.totalPages;
      state.totalCategories = action.payload.meta.total;
      state.dataFetched = true;
    });
    builder.addCase(getSubAdmin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // GET All SUBADMIN DATA
    builder.addCase(getAllSubAdmin.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllSubAdmin.fulfilled, (state, action) => {
      state.loading = false;
      state.allSubAdmin = action.payload.data;
    });
    builder.addCase(getAllSubAdmin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // UPDATE SUBADMIN DATA
    builder.addCase(updateSubAdmin.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateSubAdmin.fulfilled, (state, action) => {
      const index = state.subAdmins.findIndex(
        (subAdmin) => subAdmin._id === action.payload._id
      );
      if (index !== -1) {
        state.subAdmins[index] = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(updateSubAdmin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // SOFT DELETE SUBADMIN DATA
    builder.addCase(softDeleteSubAdmin.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(softDeleteSubAdmin.fulfilled, (state, action) => {
      const deletedId = action.payload.data._id || [];
      state.subAdmins = state.subAdmins.filter(
        (subAdmin) => !deletedId.includes(subAdmin._id)
      );
      state.loading = false;
    });
    builder.addCase(softDeleteSubAdmin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // UNDO DELETE SUBADMIN DATA
    builder.addCase(undoDeleteSubAdmin.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(undoDeleteSubAdmin.fulfilled, (state, action) => {
      const undodeletedId = action.payload.data._id || [];
      state.subAdmins = state.subAdmins.filter(
        (subAdmin) => !undodeletedId.includes(subAdmin._id)
      );
      state.loading = false;
    });
    builder.addCase(undoDeleteSubAdmin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // MULTIPLE SUBADMIN DATA
    builder.addCase(softDeleteSubAdmins.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(softDeleteSubAdmins.fulfilled, (state, action) => {
      const deletedIds = action.payload || [];
      state.subAdmins = state.subAdmins.filter(
        (subAdmin) => !deletedIds.includes(subAdmin._id)
      );
      state.loading = false;
    });
    builder.addCase(softDeleteSubAdmins.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export default subAdminSlice.reducer;
